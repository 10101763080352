export const setupServerMiddleware = (id, path, callback, options = {}) => {
    if (__SERVER__) {
        const express = require('express');
        const middleware = express.Router();

        // Apply body-parsing middleware only if specified in options
        if (options.parseJson) {
            middleware.use(express.json());
        }
        if (options.parseUrlEncoded) {
            middleware.use(express.urlencoded({ extended: true }));
        }

        middleware.all(path, callback);
        middleware.id = id;
        return middleware
    }
}